import * as React from 'react'

interface Props {
  message: string
  type: 'error' | 'warning' | 'success'
  fixed?: boolean
}

export default function FlashComponent ({ message, type, fixed }: Props) {
  const mobile = window.innerWidth < 900

  return <div className={ `msgNotif ${fixed ? 'msgNotif--fixed' : ''} msgNotif--${type} ${mobile ? 'msgNotif--mobile' : ''}` }>
    <div className="msgNotif-txt">
      { message }
    </div>
  </div>
}
