import $ from 'jquery';
import Clipboard from 'clipboard';

export function copyLinkCasting () {
  const clipboard = new Clipboard('.js-btnClipboard');

  clipboard.on('success', function (e) {
    $(e.trigger).addClass("is-copied").delay(1000).queue(function(){
      $(this).removeClass("is-copied");
    });
  });
}
