import $ from 'jquery';
import * as noUiSlider from 'nouislider';
import 'typescripts/components/shared/input-range.component.css';
import { debounce } from 'typescripts/services/utils/lodash.service';

export const range = elementId => {
  const rangeItem       = document.getElementById(elementId);
  if (rangeItem) {
    const rangeItemMin    = rangeItem.getAttribute('data-min');
    const rangeItemMax    = rangeItem.getAttribute('data-max');
    const rangeItemInit   = rangeItem.getAttribute('data-init');
    const rangeItemPrefix = rangeItem.getAttribute('data-prefix');
    const rangeItemUnit   = rangeItem.getAttribute('data-unit');
    const rangeItemStep   = rangeItem.attributes['data-step'] ? rangeItem.getAttribute('data-step') : 1
    const rangeTooltip    = rangeItem.getAttribute('data-tooltip') == "false" ? false : true
    const rangeRound      = rangeItem.attributes['data-round'] ? rangeItem.getAttribute('data-round') : 0

    return noUiSlider.create(rangeItem, {
      start: Number(rangeItemInit),
      tooltips: rangeTooltip,
      connect: 'lower',
      step: Number(rangeItemStep),
      range: {
        'min': Number(rangeItemMin),
        'max': Number(rangeItemMax) == 0 ? 1 : Number(rangeItemMax),
      },
      format: {
        to: value => `${rangeItemPrefix}${value.toFixed(rangeRound)}${rangeItemUnit}`,
        from: parseFloat,
      },
    });
  }
};

export const addRangeValue = (elementId, inputID) => {
  const rangeItem = document.getElementById(elementId);
  $(inputID).val(rangeItem.getAttribute('data-init'));
  rangeItem.noUiSlider.on('change', debounce(function( values, handle ) {
    $(inputID).val(parseFloat(values[handle]));
  }, 500));
};

export const addPrescriberRangeValues = (elementId, input, div1, div2) => {
  const rangeItem = document.getElementById(elementId);
  const discount  = Number(rangeItem.getAttribute('data-init'));
  const reward = Number(rangeItem.getAttribute('data-max')) - discount;
  $(input).prop('value', discount);
  $(div1).html(discount + '%');
  $(div2).html(reward + '%');
  rangeItem.noUiSlider.on('change', debounce(function( values, handle ) {
    const value = parseFloat(values[handle].match(/\d*\.*\d*/g)[0])
    $(input).val(value);
    $(div1).html(value + '%');
    $(div2).html(Number(rangeItem.getAttribute('data-max')) - value + '%');
  }, 10));
};
