import { firebaseCloudMessagingTokenCreate } from "typescripts/services/models/firebase-cloud-messaging-tokens.service";
import { stripeTerminalsConnectionToken } from "typescripts/services/models/stripe-terminals.service";
import { delegate } from 'utils/dom';

export default function cordovaCtrl () {
  document.addEventListener('deviceready', () => {
    hideSplashScreen();
    prepareFirebase();
    listenWebIntent();
    registerInAppBrowser();
    stripeTerminalRegisterConnectionTokenEventListener();
  });
}

/**
 * Cordova app displays a flash screen at start.
 * We should hide it when the js files are loaded.
 */
function hideSplashScreen (tries = 0) {
  // Hide flash screen for cordova if active
  if (typeof navigator.splashscreen !== 'undefined') {
    navigator.splashscreen.hide();
  } else if (tries < 10) {
    setTimeout(() => hideSplashScreen(tries + 1), 1000);
  }
}

/**
 * Cordova app can receive push notification once a firebase token is
 * registred and sent to the server. Tokens are linked to current user account.
 */
async function prepareFirebase () {
  if (!cordova?.plugins.firebase?.messaging.requestPermission) return;

  if (window.missing_firebase_token) {
    await cordova.plugins.firebase.messaging.requestPermission({ forceShow: true });
    const token = await cordova.plugins.firebase.messaging.getToken();
    const packageName = await cordova.getAppVersion.getPackageName();
    await firebaseCloudMessagingTokenCreate({ token, package: packageName });
  }

  cordova.plugins.firebase.messaging.onBackgroundMessage(function (payload) {
    // user clicked on a notification, app react by folowing the link.
    if (payload.path) {
      loadOverlay();
      window.location.href = payload.path + (payload.path.includes('?') ? '&' : '?') + 'from_push=true';
    }
  });
}

/**
 * Web intents allow the cordova app to catch url normally open in the browser and
 * launch the cordova app instead.
 */
function listenWebIntent () {
  if (!window.IonicDeeplink) return;

  window.IonicDeeplink.route({}, () => {}, nomatch => {
    loadOverlay();
    window.location.href = nomatch.$link.url;
  });
}

function loadOverlay () {
  document.body.innerHTML += `
    <div style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; background-color: #000000AA; display: flex; justify-content: center; align-items: center; z-index: 10000">
      <div class="iconLoading"></div>
    </div>
  `
}

/**
 * For security reasons, any link opened with targte="_blank" should open
 * a new browser instance outside the cordova app (without access to session nor cordova APIs).
 * 
 * @deprecated This function should be removed once most customers updated to the latest versions of mobile apps.
 */
function registerInAppBrowser () {
  if (!cordova?.InAppBrowser?.open) return;

  window.open = cordova.InAppBrowser.open;

  window.document.addEventListener('click', delegate('a[target="_blank"]', function (event) {
    event.preventDefault();
    event.stopPropagation();
    window.open(this.href, '_system');
  }));
}

/**
 * When in the context of the cordova application, the StripeTerminal SDK
 * may ask for a connection token at any time. This event listener is
 * responsible for providing the connection token. It must be registered
 * on every page of the application if user can use StripeTerminal.
 */
function stripeTerminalRegisterConnectionTokenEventListener () {
  window.ogustineBridge?.setEventListener?.(async message => {
    if (message !== 'stripeTerminalNeedsConnectionToken') return
    
    let secretKey = null

    try {
      secretKey = (await stripeTerminalsConnectionToken()).secret_key
    } catch (error) {
      await window.ogustineBridge.emitStripeTerminalConnectionToken(null, error.message || JSON.stringify(error))
      throw error
    }

    await window.ogustineBridge.emitStripeTerminalConnectionToken(secretKey, null)
  })
}
