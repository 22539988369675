import * as React from 'react'
import { I18nService } from '../../services/utils/i18n.service'
import { httpGetRoute } from '../../services/utils/http.service'
import { debounce } from '../../services/utils/lodash.service'
import MenuSideComponent from './menu-side.component'
import { useOnResize } from '../../hooks/use-on-resize'

interface Props { menu: Menu }
export interface MenuItem { href: string, icon: string, text: string, active: boolean, className?: string, count?: number }
export interface MenuItemCollection { items: MenuItem[], accountItems: MenuItem[] }

const i18nScoped = new I18nService(require('./menu.component.yml'))
const LazyClockingButtonComponent = React.lazy(() => import('../employees/clockings/clocking-button.component'))

export default function MenuComponent ({ menu, menu: { logo, baseline, employee, customer, backoffice, messaging } }: Props) {
  const shouldUseCompactLayout = React.useCallback(() => {
    return window.innerWidth < 1200 || location.href.includes('join_meeting') || window.currentUser?.type === 'Operator'
  }, [])

  const [opened, setOpened] = React.useState(false)
  const [onSmallDevice, setOnSmallDevice] = React.useState(shouldUseCompactLayout())
  const [innerHeight, setInnerHeight] = React.useState(window.innerHeight)

  const menuRef = React.useRef<HTMLDivElement>(null)

  useOnResize(() => debounce(() => {
    setOnSmallDevice(shouldUseCompactLayout())
    setInnerHeight(window.innerHeight)
  }, 50)(), [shouldUseCompactLayout])

  // Put the generated HTML in cache. It will be shown on next navigation until react got initialized.
  React.useEffect(() => { localStorage.setItem('cacheMenu', menuRef.current.innerHTML) })

  const homePath = React.useMemo(() => {
    return menu.employee?.candidateWithLimitedAccess ? `/${httpGetRoute()}/principal_information` : `/${httpGetRoute()}`
  }, [menu.employee?.candidateWithLimitedAccess])

  return <div ref={ menuRef }>
    <MenuSideComponent
      menu={ menu }
      innerHeight={ innerHeight }
      onSmallDevice={ onSmallDevice }
      homePath={ homePath }
      opened={ opened }
      setOpened={ setOpened }/>

    <header className="headerMain" role="banner" id="header">
      <div className="containerPage-inner" style={{ display: 'flex', alignItems: 'center' }}>
        { /** hamburger menu icon on the left*/ }
        { onSmallDevice && <a className="menu-icon icon-menu" onClick={ () => setOpened(!opened) }></a>  }

        { /** the market logo, visible on large screens */ }
        <a href={ homePath } className="hidden-xs"><img className="u-mls" src={ logo.url } alt={ logo.alt } /></a>
        { window.currentUser.type === 'Employee' && <a className="u-mrs visible-xs icon-home menu-icon" href={ homePath }></a> }

        { /** Operators have an additional menu on top of the desktop screen while users have the baseline instead */ }
        { window.currentUser.type !== 'Operator' && <p className="headerMain-baseline hidden-xs">{ baseline }</p> }
        
        { /** clocking (for employee) or new casting (customer) button. This button is centered on phones and small tablettes */ }
        <span style={{ flexGrow: 1 }}></span>
        { employee.clockings && !employee.candidateWithLimitedAccess && <React.Suspense fallback={ null }><LazyClockingButtonComponent /></React.Suspense> }
        
        { (customer.newCasting || employee.newCasting) && <a
          className="u-mrm u-mlm button button--S"
          href={ `/${window.market_type.toLocaleLowerCase()}s/${window.market_id}/castings/new` }
          children={ i18nScoped.t('new_casting') } /> }

        { window.currentUser.type === 'Operator' && window.market_type === 'Supplier' && <div className="headerMain-middle">
          <a className="headerMain-middle-link" href={ `/${httpGetRoute()}/castings` }>{ i18nScoped.t('castings') }</a>

          <a className="headerMain-middle-link" style={{ position: 'relative' }} href={ `/${httpGetRoute()}/plannings` }>
            { i18nScoped.t('events_operator') }
            { backoffice.anyIncomingEventToUpdate && <span className="menu-icon-count" style={{ position: 'static', height: '1.3em', marginLeft: '0.4em' }} /> }
          </a>

          <a className="headerMain-middle-link" href={ `/${httpGetRoute()}/customers` }>{ i18nScoped.t('customers') }</a>
          
          { backoffice.soloEdition
            ? <a className="headerMain-middle-link" href={ `/${httpGetRoute()}/invoices` }>{ i18nScoped.t('invoicing') }</a>
            : <a className="headerMain-middle-link" href={ `/${httpGetRoute()}/employees` }>{ i18nScoped.t('employees') }</a> }

          { backoffice.adminSupplier && <a
            className="headerMain-middle-link"
            href={ `/${httpGetRoute()}/offered_services` }
            children={ i18nScoped.t('offered_services_and_params') } /> }
        </div> }
        <span className="visible-xs-block" style={{ flexGrow: 1 }}></span>

        { /** icons on the right, including customerFollowUpLiaisonBook (for customer), messaging and cart (for customer) */ }
        { customer.followUpLiaisonBook?.active && <a className="u-mrs icon-address-book menu-icon" href={ customer.followUpLiaisonBook.url }>
          { !!customer.followUpLiaisonBook.count && <span className="menu-icon-count">{ customer.followUpLiaisonBook.count }</span> }
        </a> }

        { messaging?.active && <a className="u-mrs icon-messaging menu-icon" href={ `/${httpGetRoute()}/messaging_conversations` }>
          { !!messaging.count && <span className="menu-icon-count">{ messaging.count }</span> }
        </a> }

        { customer.cart?.active && <a className="u-mrs icon-shopping-cart menu-icon" href={ customer.cart.url }>
          { !!customer.cart.count && <span className="menu-icon-count">{ customer.cart.count }</span> }
        </a> }
      </div>
    </header>
  </div>
}
