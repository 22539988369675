import 'utils/polyfills';
import 'utils/rollbar';
import 'jquery-modal';
import 'select2';

import $ from 'jquery';
import { initToggleSiblings, initAttrThenElse } from 'utils/dom';
import 'lightbox2';

import RailsUjs from '@rails/ujs';
window.Rails = RailsUjs;
RailsUjs.start();

import { checksAllLinksAreSafe } from 'typescripts/services/utils/security.service';
import { dropdown } from 'components/shared/dropdown';
import { SlickNav } from 'components/shared/slickNav';
import { modal } from 'components/shared/modal';
import { sliderDashboard } from 'components/shared/sliderDashboard';
import { copyLinkCasting } from 'components/backoffice/copyLinkCasting';
import { navSettings } from 'constants/main/main.const';
import autoloadComponents from 'typescripts/components/auto-load';

import DeviseCtrl from 'controllers/shared/devise.ctrl';
import NewMarketCtrl from 'controllers/backoffice/newMarket.ctrl';
import CordovaCtrl from 'controllers/shared/cordova.ctrl';
import inlineCalendarCtrl from 'controllers/shared/inlineCalendar.ctrl';
import MembershipShowCtrl from 'controllers/backoffice/membershipShow.ctrl';
import UploadPhoneNumbersModal from 'components/shared/uploadPhoneNumbersModal';
import UploadMessagingConversationsCustomerOrEmployeeModal from 'components/backoffice/messaging/uploadMessagingConversationsCustomerOrEmployeeModal';

// ensure scrolling into a input['number'] does not increment / decrement the value
$(document).on("wheel", "input[type=number]", e => $(e.target).trigger('blur'));
$(document).on('modal:open', autoloadComponents);

$(document).ready(() => {
  dropdown('.dropdown-link');
  const $searcControl = $('#search-control');
  const $headerMainMiddle = $('.headerMain-middle');
  $searcControl.focus(() => $headerMainMiddle.fadeOut());
  $searcControl.focusout(() => $headerMainMiddle.fadeIn());
  SlickNav(':not(.content-aside) > .menuAside-nav', '.menuAside-nav-link', navSettings);
  modal();
  initToggleSiblings();
  initAttrThenElse();
  checksAllLinksAreSafe();

  // Dashboard
  sliderDashboard('#slider-castingProfil', '#slider-castingProfil .profil');

  //  Init for basic search select
  $('.js-select').select2();
  $('.js-select-noSearch').select2({ minimumResultsForSearch: Infinity });
  $('.js-select-tag').select2({ tags: true });


  // Show or hide clocking qrcode on customer dashboard
  $('#qrCode').on('click', (e) => {
    e.preventDefault();
    $('.qr-code').slideToggle()
  });
  $('#qrCodeSmall').on('click', (e) => {
    e.preventDefault();
    $('.qr-code-small').slideToggle();
  });

  // Controllers
  autoloadComponents();
  DeviseCtrl();
  inlineCalendarCtrl();
  MembershipShowCtrl();
  NewMarketCtrl();
  CordovaCtrl();

  UploadPhoneNumbersModal();
  UploadMessagingConversationsCustomerOrEmployeeModal();
  copyLinkCasting();
});
