// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js!../../../../../../../node_modules/nouislider/dist/nouislider.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".noUi-container {\n  height: 8px;\n  margin-top: 0.6em;\n}\n\n.noUi-handle:before, .noUi-handle:after {\n  display: none;\n}\n\n.noUi-horizontal .noUi-handle {\n  border-radius: 14px;\n  width: 20px;\n  height: 20px;\n  box-shadow: none;\n  top: -8px;\n  transform: translateX(-7px);\n}\n\n.noUi-touch-area {\n  border-radius: 1em;\n}\n\n.noUi-horizontal .noUi-tooltip {\n  bottom: -40px;\n}\n", "",{"version":3,"sources":["webpack://./app/assets/javascripts/src/typescripts/components/shared/input-range.component.css"],"names":[],"mappings":"AAEA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,SAAS;EACT,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf","sourcesContent":["@import 'nouislider/dist/nouislider.css';\n\n.noUi-container {\n  height: 8px;\n  margin-top: 0.6em;\n}\n\n.noUi-handle:before, .noUi-handle:after {\n  display: none;\n}\n\n.noUi-horizontal .noUi-handle {\n  border-radius: 14px;\n  width: 20px;\n  height: 20px;\n  box-shadow: none;\n  top: -8px;\n  transform: translateX(-7px);\n}\n\n.noUi-touch-area {\n  border-radius: 1em;\n}\n\n.noUi-horizontal .noUi-tooltip {\n  bottom: -40px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
