import { handleUnknownErrorCode } from "../../components/shared/error-box.component"
import { domAppendFlash, windowScrollTo } from "./dom.service"

export function errorsStateHandler (errorHandler: (this: any, errors: string[]) => void, scrollTopOnError = false) {
  return function <F extends (...args: any[]) => Promise<boolean>>(target: any, propertyKey: string, descriptor: TypedPropertyDescriptor<F>) {
    descriptor.value = errorWrapInHandler(target[propertyKey], errorHandler, scrollTopOnError)
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function errorWrapInHandler<T extends Function> (originalMethod: T, errorHandler: (this: any, errors: string[]) => void, scrollTopOnError = false) {
  return async function (this: any, ...args: any[]) {
    try {
      const originalReturn = await originalMethod.apply(this, args)
      errorHandler.apply(this, [[]])
      return originalReturn
    } catch (err) {
      if (err.flash) return false // processed into httpParseJsonAndFlash
      errorHandler.apply(this, [err?.full_messages || ['unknown_error']])
      if (!err?.full_messages) throw err
      if (scrollTopOnError) windowScrollTo({ top: 0, behavior: 'smooth' })
      return false
    }
  } as any  as T
}

export function errorsSetFlash (errors: string[]) {
  if (!errors.length) return
  
  const formattedErrors = handleUnknownErrorCode(errors)
    .map(error => error.endsWith('.') ? error : error.concat('.'))
    .join(' ')
  
  domAppendFlash('alert', formattedErrors)
}
